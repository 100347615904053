<template>
  <!-- <AppLayout>
    <keep-alive> -->
      <router-view v-if="!loading"></router-view>
    <!-- </keep-alive>
  </AppLayout> -->
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('products');

export default {
  computed: {
    ...mapState(['loading']),
  },
  methods: {
    ...mapActions([
      'getProducts',
      'findBoostHistoric',
      'getCategories',
      'getVariations',
      'getPromotions',
      'getSuppliers',
    ]),
  },
  created() {
    this.getCategories();
    this.getProducts();
    this.getVariations();
    this.getPromotions();
    this.getSuppliers();
    this.findBoostHistoric();
  },
};
</script>

<style>
</style>
